body{
    width: 100vw;
    overflow-x: hidden;
}

@font-face {
font-family:fserstad_a;
src: url('../public/FSerStdA-Book.ttf');
}

@font-face {
font-family:fserstad_b;
src: url('../public/Fedra Sans Std Medium.otf');
}

.font_normal{
font-family:fserstad_a !important;
}

.font_medium{
    font-family:fserstad_b !important;
    }

#font_normal_with_space{
    font-family:fserstad_a !important;
    margin-bottom: 10px !important;
    font-size: 14px !important;
}

#font_medium_with_space{
    font-family:fserstad_b !important;
    margin-bottom: 10px !important;
    font-size: 14px !important;

}
